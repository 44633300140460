import React, { useEffect, useState } from 'react';
import { globalStyles } from '../../../global.css';
import { styles } from './ApppishFeature.css';
import { ApppishFeatureIcon } from './ApppishFeatureIcon';
import { MockFeatureList } from '../../../mocks/MockObjs';


export const ApppishFeature = (props) => {
    const classStyles = styles();
    const globalCss = globalStyles();

    const [features, setFeatures] = useState([]);

    useEffect(() => {
        setFeatures(MockFeatureList);
    }, []);

    return (
        <section className="container mt-4">
            <div className="row">
                <div className={classStyles.hr}></div>
            </div>
            <div className="row justify-content-center">
                <h3 className={globalCss.header}>Our Focus</h3>
            </div>
            <div className="row justify-content-center">
                <h4 className={globalCss.subtitle}>Deliverying Across Multiple Domains</h4>
            </div>
            <div >

           
            <div className={`${classStyles.iconSectionContainer} row`}>
                {features.slice(0,3).map((feature, index) => {
                    
                    return (
                       
                        <ApppishFeatureIcon key={feature.text} imageUrl={feature.imageUrl} text={feature.text} classStyles={classStyles}></ApppishFeatureIcon>
                       
                    );
                }
                )}
                {features.slice(3).map((feature, index) => {
                    
                    return (
                       
                        <ApppishFeatureIcon key={feature.text} imageUrl={feature.imageUrl} text={feature.text} classStyles={classStyles}></ApppishFeatureIcon>
                       
                    );
                }
                )}
            </div>
           
            </div>
        </section>
    );
}