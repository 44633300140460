import React from 'react';
import {globalStyles} from '../../../global.css'
import Logo from '../../../public/logo.svg'

const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };


export const ApppishFooter = (props) => {
    const globalCss = globalStyles();

    return (
        <footer className={`${globalCss.footerBg} container-fluid`}>
            
            {props.children}

   
            
            <div className={`${globalCss.footer} `}>
            <div className={`row` }>
                    
                      
                        <div className={`col-lg-2 d-flex justify-content-center`}>
                        <img className={`${globalCss.footerLogo} img-fluid mb-2`} src={Logo} />
                        </div>
                   
                 
                    <div className={`col-lg-8 d-flex justify-content-center`}>
                        <p className={`${globalCss.copyRight}`}>&#169; 2021 Apppish, LLC All rights reserved</p>
                    </div>
                   
                    <div className={`col-lg-2`}>
                       <p onClick={scrollToTop} className={`${globalCss.backToTop}`}>Back to Top</p>
                    </div>
                  
                </div>
            </div>
        </footer>
    );
}