import {createUseStyles} from 'react-jss';
import footerBG from './public/footer-bg/footerBg.svg'


export const globalStyles = createUseStyles({
    header: {
        fontFamily: 'Helvetica-Bold',
        fontSize: '52px',
        color: '#297FCA',
        letterSpacing: '-2px',
        textAlign: 'center',
        lineHeight: '58px',
        marginTop: "0.4em"
    },
    subtitle: {
        fontFamily: "MaxwellSmCaps-Book",
        opacity: 0.64,
        fontSize: "30px",
        color: "#297FCA",
        letterSpacing: 0,
        textAlign: "center",
        lineHeight: "30px"
    },
    test: {
        paddingTop: "1.4em",
        paddingBottom: "0em",
        verticalAlign: "bottom",
        height: "100%"
    },
    highlight: {
        fontWeight: "bold",
        opacity: 1.0,
        fontFamily: "MaxwellSmCaps-Book",
        fontSize: "26px",
        color: "#297FCA",
        letterSpacing: 0,
        lineHeight: "30px"
    },
    text: {
        opacity: 0.8,
        fontFamily: "Helvetica",
        fontSize: "22px",
        color: "#297FCA",
        letterSpacing: 0,
        lineHeight: "38px"
    },
    footer: {
      maxHeight: "100px",
       minWidth: "100%"
       
    },
    footerBg: {
        position: "relative",
        minHeight: "900px",
        maxWidth: "3000px",
        backgroundImage: `url(${footerBG})`,
        // backgroundRepeat: 'no-repeat',
        paddingTop: "15em",
        paddingBottom: 0,
        marginRight: 0
        
    },
    copyRight: {
        opacity: "0.8",
        fontFamily: "Helvetica-Bold",
        fontSize: "16px",
        color: "#297FCA",
        letterSpacing: 0,
        lineHeight: "25px",
        width: "250px",
        textAlign: "left"
    },
    backToTop: {
        fontFamily: "Helvetica-Bold",
        fontSize: "16px",
        color: "#297FCA",
        letterSpacing: 0,
        textAlign: "center",
        lineHeight: "19px",
        cursor: "pointer"
    },
    transparent: {
        backgroundColor: "transparent",
        borderWidth: 0
    },
    borderless: {borderWidth: 0},
    footerLogo1: {maxWidth: "200px"},
    textWhite: {color: "#fff", verticalAlign: "center"},
    contactSuccessMsg: {
        fontFamily: "MaxwellSmCaps-Book",
        fontSize: "46px",
        color: "#FFFFFF",
        letterSpacing: 0
    },
    homeBtn: {
        margin: "1em 0",
        minWidth: "10em",
        background: "#3694DC",
        border: "2px solid #FFFFFF",
        boxShadow: "0 2px 4px 0 rgba(0,0,0,0.50)",
        borderRadius: "8em",
        fontFamily: "MaxwellSmCaps-Book",
        fontSize: "36px",
        letterSpacing: 0
    }
});