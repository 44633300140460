import React from 'react';
import { styles } from './ApppishBetterBusiness.css';
import {globalStyles} from '../../../global.css'
import betterBusiness from '../../../public/better-business/bb.svg';
import { ApppishContactButton } from '../buttons/ApppishContactButton';


export const ApppishBetterBusiness = (props) => {
    const classStyles = styles();
    const globalCss = globalStyles();

    return (
        <section className="container-fluid p-4 mt-5">
            <div className="row justify-content-center">
                <h1 className={classStyles.header}>Business is better with tech</h1>
            </div>
            <div className="row justify-content-center mb-5">
                <h3 className={classStyles.subtitle}>…tell us your problem and we’ll find the tech solution</h3>
            </div>
            <div className="row">
                <div className="col-md-6 col-sm-12 d-flex justify-content-center">
                    <img className='text-center' src={betterBusiness}></img>
                </div>

                <div className="col-md-6 col-sm-12 d-flex justify-content-center">
                    <ul className="row">
                        <li className={globalCss.text}>Increase Customer Interactions with <span className={globalCss.highlight}>In-App Messaging</span>.</li>
                        <li className={globalCss.text}>Simply Shopping Expriences with <span className={globalCss.highlight}>E-Commerce</span> and <span className={globalCss.highlight}>Payment</span></li>
                        <li className={globalCss.text}>Elevate Business Presence with Modern <span className={globalCss.highlight}>Web</span> and <span className={globalCss.highlight}>Mobile Applications</span></li>
                        <li className={globalCss.text}>Create a community of users with <span className={globalCss.highlight}>Social Networking</span>.</li>
                    </ul>

                </div>

            </div>
           <ApppishContactButton></ApppishContactButton>

        </section>
    );
}