import React from 'react';
import { globalStyles } from '../../../global.css';
import {styles} from './ApppishTechStack.css';

export const ApppishTechStack = (props) => {
    const classStyles = styles();
    const globalCss = globalStyles();
    const stack = ["Java", "React", "AWS", "JavaScript", "React Native", "SQL/NOSQL", "Spring Boot", "Node.js"]
    return (
        <section className={`container`}>
            <div className={`row px-3`}>
                <div className={`col-lg-5`}>
                    <div className={`row `}>
                        <p className={`${classStyles.techStackDescription}`}>
                        Deliverying software solutions from start to finish. Seeminglessly intergrating components from the front and backend.
                        </p>
                    </div>

                    <div className={`row`}>
                        <p className={`${globalCss.subtitle} ${classStyles.subtitle}`}>Full Stack Dev</p>
                    </div>
                </div>
                <div className={`col-lg-7`}>
                    <div className={`row`}>
                        {stack.map((tech) => {return(<div className={`${classStyles.techStackTextContainer} col-md-4 col-lg-3 col-sm-6`}>
                        <h2 className={`text-center ${classStyles.techStackText}`}>{tech}</h2>
                        </div>)})}
                    </div>
                </div>
            </div>
        </section>
    );
}