import React, {useState} from 'react';
import {ApppishTechTopic} from './ApppishTechTopic';
import {styles} from './ApppishForm.css';


export const ApppishTechTopics = (props) => {
    const classStyles = styles();
    const topics = [{serviceName: "Consultation", id: 1}, {serviceName: "Start Up", id: 2}, {serviceName: "Project Uplift", id: 3}]

    return (
        <section className={`container`}>
            <div className={`row  ${classStyles.topicsContainer}`}>
                {topics.map(topic => <ApppishTechTopic id={topic.id} name={topic.serviceName} topicSelected={props.topicSelected}></ApppishTechTopic>)}
            </div>
        </section>
    );
}