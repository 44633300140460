import {createUseStyles} from 'react-jss';

export const styles = createUseStyles({
    main: {
        margin: {
            top: 10
        }
    },
    hr: {
        width: '90%',
        height: '1px',
        opacity: 0.3,
        background: '#3FA2F7',
        margin: {
            top: 0,
            left: "auto",
            right: 'auto',
            bottom: 50
        }
    },
    sectionHeaderText: {
        fontFamily: 'Helvetica-Bold',
        fontSize: '52px',
        color: '#297FCA',
        letterSpacing: '-2px',
        textAlign: 'center',
        lineHeight: '58px'
    },
    sectionSubHeaderText: {
        opacity: 0.8,
        fontFamily: 'Helvetica-Bold',
        fontSize: '20px',
        color: '#297FCA',
        letterSpacing: 0,
        textAlign: 'center',
        lineHeight: '30px'
    },
    iconSectionContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: "60%",
        margin: {
            top: '1em',
            left: 'auto',
            right: 'auto',
            bottom: '2.2em'
        }
    },
    iconText: {
        fontFamily: 'Helvetica-Bold',
        fontSize: '20px',
        color: '#297FCA',
        textAlign: 'center',
        lineHeight: '30px',
        width: "150px",
        paddingTop: "1em",
        verticalAlign: 'center'
    }
})