import {createUseStyles} from 'react-jss';

export const styles = createUseStyles({
    mainContainer: {
        position: "relative"
    },
    projectDetails: {
        minWidth: "90%",
        minHeight: "10em"
    },
    topicsContainer: {
        borderRadius: "20em",
        borderWidth: "1px",
        borderColor: "black",
        marginBottom: "2em"
    },
    topic: {
        cursor: "pointer"
    },
    label: {
        fontFamily: "MaxwellSmCaps-Book",
        fontSize: "20px",
        color: "#297FCA",
        letterSpacing: 0
    },
    input: {
        borderRadius: "0.5em",
        minWidth: "85%",
    },
    smallText: {
        fontSize: "0.8em"
    }
})