import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import{ init } from 'emailjs-com';
import {HomeScreen} from '../src/screens/home/HomeScreen';
import { ApppishFooter } from './components/shared/footer/ApppishFooter';
import { ApppishForm } from './components/shared/contact/form/ApppishForm';

function App() {
  init("user_7FmAUGCIon85teeMt33zs");
  return (
    <div className="App">
      <Router>
        {/* <HomeScreen></HomeScreen> */}

        <Switch>
          <Route path="/contact">
            <ApppishForm />
          </Route>
          <Route path="/">
            <HomeScreen />
          </Route>
        </Switch>
      </Router>
      
    </div>
  );
}

export default App;
