import Logo from "../public/logo.svg";
import Landing from "../public/Landing.png";
import CosultLogo from '../public/consult-icon/Icon.svg';
import CloudIcon from '../public/cloud-icon/Icon.svg';
import CrossPlaform from '../public/cross-platform/Icon.svg';

import Inspire from '../public/inspiration-icon/Icon.svg';
import Microservice from '../public/microservice-icon/Icon.svg';
import Test from '../public/test-auto-icon/Icon.svg';



export const MockHeaderLeftTextObj = {
    top: Logo,
    mid: "Starts with an Idea",
    bottom: "Converting your ideas into applications shouldn’t be hard...so let us help"
}

export const MockHeaderRightImgUrl = {
    top: "",
    mid: Landing,
    bottom: ""
}

export const MockFeatureList = [
    { text: "Remote Consultations", imageUrl: CosultLogo }, { text: "Cloud Intergration", imageUrl: CloudIcon }, { text: "Cross Platform Development", imageUrl: CrossPlaform }, { text: "Distributed System Design", imageUrl: Microservice }, { text: "Test Automation", imageUrl: Test }, { text: "Inspired By You", imageUrl: Inspire }];


export const MockSliderList = [{
    id: 1,
    title: "Slide 1",
    imageUrl: "url.image",
    body: "some Text",

}]