import React, {useState} from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import { ApppishFooter } from '../../footer/ApppishFooter';
import { ApppishContactForm } from './ApppishContactForm';
import { ApppishTechTopics } from './ApppishTechTopics';
import {globalStyles } from '../../../../global.css';
import { styles } from './ApppishForm.css';
import emailjs from 'emailjs-com';
import SUCCESS from '../../../../public/success/success.svg'
import { Link } from 'react-router-dom';

export const ApppishForm = (props) => {
    const globalCss = globalStyles();
    const classStyles = styles();

    const [techTopic, setTechTopic] = useState([]);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [contactMethod, setContactMethod] = useState('');
    const [contactId, setContactId] = useState('');
    const [company, setCompany] = useState('');
    const [projectName, setProjectName] = useState('');
    const [projectInfo, setProjectInfo] = useState('');
    const [success, setSeccess] = useState(false);
    const [loading, setLoading] = useState(false);

    const topicSelected = (topic) => {
        console.log(topic);
        setTechTopic((prevState) => {
            if(!topic.selected) {
               return  prevState.filter(temp => temp !== topic.name);
               
            }else{
                const newTopics = [...prevState];
                newTopics.push(topic.name);
                return newTopics;
            }
           
           
        })
    }

    const getContactIdPlaceholder =()=> {
        switch(contactMethod) {
            case "Phone":
                return "phone number"
            case "Zoom":
                return "zoom id"
            case "In-Person":
                return "City, St"
        }
    }

    const updateField = (event) => {
        switch(event.target.name) {
            case "name":
                setName(event.target.value);
                break;
            case "email":
                setEmail(event.target.value);
                break;
            case "company":
                setCompany(event.target.value);
                break;
            case "projectDetails":
                setProjectInfo(event.target.value);
                break;
            case "contactMethod": 
                setContactMethod(event.target.id);
                break;
            case "projectName": 
                setProjectName(event.target.value);
                break;
            default:
                setContactId(event.target.value);

        }

       
    }

    const submitForm =  () => {
        setLoading(true);

        emailjs.send("service_z8ga7lm","template_vogacxc", {name, company, email, projectName, projectInfo, contactMethod, contactId, techTopic: techTopic.toString()}).then((response) => {
            setSeccess(true);
            console.log('SUCCESS!', response.status, response.text);
            setLoading(false);
         }, (err) => {
             setSeccess(false);
            console.log('FAILED...', err);
            setLoading(false);
         });
    }

    return (
        <section className={`mt-3 ${classStyles.mainContainer}`}>
            <h1 className={`${globalCss.subtitle}`}>Get in touch</h1>
            <ApppishFooter>

            {!success && !loading && ( <div className={`container`}>
            <ApppishTechTopics topicSelected={topicSelected}></ApppishTechTopics>
            <ApppishContactForm placeholder={getContactIdPlaceholder} updateField={updateField} onSubmit={submitForm}></ApppishContactForm>
            </div>)}

            {loading && (<div className={`container`}>
                <div className={`row justify-content-center`}>
                    <ClipLoader color={"blue"} loading={loading}  size={150} />
                </div>
            </div>)}

            {success && (
                <div className={`container`}>
                    <div className={`row justify-content-center pb-5`}>
                        <h2 className={`${globalCss.header} ${globalCss.contactSuccessMsg}`}>Be In Touch Soon</h2>
                    </div>
                    <div className={`row justify-content-center`}>
                    <img className={`align-self-center`} className src={SUCCESS}></img>
                    </div>
                    <div className={`row justify-content-center`}>
                        <Link to="/">
                        <div className={`btn btn-primary btn-lg  ${globalCss.homeBtn}`}><p className={`${globalCss.subtitle} ${globalCss.textWhite}`}>Return Home</p></div>
                        </Link>
                       
                    </div>
                </div>
            )}
           
            </ApppishFooter>
        </section>
    );

 

}

