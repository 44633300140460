import React, { useState, useEffect } from 'react';
import { motion } from "framer-motion";

export const ApppishFeatureIcon = (props) => {
    const [visable, setVisable] = useState(false); 
    const visableRef = React.useRef(null); 
    const callbackFun = (entries) => {
        const [entry] = entries;
        setVisable(entry.isIntersecting);

    }

    const options = { threshold: 0.60, rootMargin: "0px", root: null };



    useEffect(() => {
        const observer = new IntersectionObserver(callbackFun, options);
        if (visableRef.current) {
            observer.observe(visableRef.current);
        }
    }, []);

    const { classStyles } = props;

    return (

        <motion.div className="col-md-6 col-lg-4 col-sm-12" ref={visableRef} transition={{ duration: 1.3 }} animate={{ opacity: visable ? 1 : 0 }}>

            <div className="row justify-content-center">
                <img src={props.imageUrl} />
            </div>
            <div className="row justify-content-center">
                <p className={classStyles.iconText}>{props.text || "Remote Consulation"}</p>
            </div>

        </motion.div>


    );
}