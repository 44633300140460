import React from 'react';
import {globalStyles} from '../../../global.css'
import {styles} from './ApppishStartup.css';
import NDA from '../../../public/NDA/NDA.svg'
import RIGHT_ARROW from '../../../public/right-arrow/rightArrow.svg'
import { ApppishContactButton } from '../buttons/ApppishContactButton';

export const ApppishStartup = (props) => {
    const classStyles = styles();
    const globalCss = globalStyles();
    return (
        <section className={`${classStyles.section} container justify-content-center`}>
            <div className="row justify-content-center">
                <h1 className={globalCss.header}>StartUp Idea?</h1>
                
            </div>
            <div className="row justify-content-center">
                
                <img src={RIGHT_ARROW} />
                <h3 className={`${globalCss.subtitle} ${globalCss.test} align-top`}>No Problem</h3>
               
                
              
            </div>

            <div  className="container row  justify-content-center">
                <div className="col-md-6 col-sm-12 align-self-center">
                    <div className="row">
                    <p className={globalCss.text}>All consultaions start with a complimentary <span className={globalCss.highlight}>Non-Disclosure Agreement</span> or <span className={globalCss.highlight}>NDA</span> for client protection and peace of mind. We are equally and as deeply invested into bringing your idea to life. <span className={globalCss.highlight}>Communication</span> and <span className={globalCss.highlight}>transparency</span> is key in any <span className={globalCss.highlight}>partnership</span>.</p>
                    </div>
                <ApppishContactButton></ApppishContactButton>
                </div>
                <div className="col-md-6 col-sm-12 ">
                    <img className={`img-fluid ${classStyles.image}`} src={NDA} />
                </div>
            </div>

        </section>
    );
}