import {createUseStyles} from 'react-jss';

export const styles = createUseStyles({
    techStackDescription: {
        opacity: 0.8,
        fontFamily: "Helvetica",
        fontSize: "20px",
        color: "#297FCA",
        letterSpacing: 0,
        lineHeight: "30px",
        maxWidth: "80%"
    },
    techStackText: {
        fontFamily: "MaxwellSmCaps-Book",
        fontSize: "36px",
        color: "#297FCA",
        letterSpacing: 0,
        textAlign: "center",
        opacity: 0.3,
        minWidth: "200px"
       
    },
    subtitle: {
        fontSize: "0.8em"
    }
})