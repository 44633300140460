import {createUseStyles} from 'react-jss';

export const useStyles = createUseStyles({
    main: {
      backgroundColor: '#E4F1FD', 
      padding: {
          left: 20,
          top: '2em',
          bottom: 20
      },
      display: 'flex',
      justifyContent: 'space-evenly'
      
    },
    leftContainer:{
        flex: 1,
        flexDirection: 'column'
    },
    leftContainerImg: {
        width: "80%"
    },
    leftContainerHeaderText: {
        fontFamily: "Helvetica-Bold",
        width: "95%",
        fontSize: "52px",
        color: "#297FCA",
        letterSpacing: "-2px",
        textAlign: "center",
        lineHeight: "58px",
        
    },
    headerRightImg: {
        width: '90%',
        margin: '0 auto',
        height: '100%',
        objectFit: 'cover',
        verticalAlign: 'bottom'
    
    },
    headerLeftText: {
        width: '75%',
        opacity: 0.8,
        fontFamily: 'Helvetica-Bold',
        fontSize: '20px',
        color: '#297FCA',
        letterSpacing: 0,
        lineHeight: '30px',
        textAlign: 'center'
    }
    
});