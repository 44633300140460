import {createUseStyles} from 'react-jss';

export const styles = createUseStyles({
    header: {
        fontFamily: 'Helvetica-Bold',
        fontSize: '52px',
        color: '#297FCA',
        letterSpacing: '-2px',
        textAlign: 'center',
        lineHeight: '58px'
    },
    subtitle: {
        fontFamily: "MaxwellSmCaps-Book",
        opacity: 0.64,
        fontSize: "30px",
        color: "#297FCA",
        letterSpacing: 0,
        textAlign: "center",
        lineHeight: "30px",
    },
    highlight: {
        fontWeight: "bold",
        opacity: 1.0,
        fontFamily: "Helvetica-Bold",
        fontSize: "20px",
        color: "#297FCA",
        letterSpacing: 0,
        lineHeight: "30px"
    },
    image: {

    },
    basicText: {
        opacity: 0.8,
        fontFamily: "Helvetica",
        fontSize: "18px",
        color: "#297FCA",
        letterSpacing: 0,
        lineHeight: "30px"
    }
   
})
