import React from 'react';
import {globalStyles} from '../../../global.css'
import {styles} from './ApppishBio.css';
import { ApppishBioPic } from './ApppishBioPic';

export const ApppishBio = (props) => {
    const classStyles = styles();
    const globalCss = globalStyles();

    return (
        <section className={`container`}>
            <div className={`row justify-content-center mb-5`}>
                <h1 className={`${globalCss.header}`}>Who Is Apppish?</h1>
            </div>
           
                <ApppishBioPic></ApppishBioPic>
                <div className={`row justify-content-center`}>
                    <p className={`${globalCss.subtitle} ${classStyles.bioText}`}>Prior Vet who loves tech. I  personally believe tech is the future. Wheather trying to solve everyday problems or in need of business solutions…I want to help bring your idea to life.</p>
                </div>
                <div className={`row justify-content-center`}>
                    <h1 className={`${globalCss.subtitle}`}>Xavier Davis</h1>
                </div>
                <div className={`row justify-content-center`}>
                    <h3 className={`${globalCss.subtitle} ${classStyles.bioSubTitle}`}>Owner</h3>
                </div>
                <div className={`row justify-content-center no-gutters gy-0`}>
                    <h3 className={`${globalCss.subtitle} ${classStyles.bioSubTitle}`}>Chief Technology Officer</h3>
                </div>
           
        </section>
    );
}