import React from 'react';
import { Link } from 'react-router-dom';


export const ApppishContactButton = (props) => {
    return (
        <div className="row justify-content-center">
        <Link to="/contact">
        <div className="btn btn-block btn-primary btn-lg">{props.text || "Reach Out"}</div>
        </Link>
    </div>
    );
}