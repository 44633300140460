import {createUseStyles} from 'react-jss';

export const styles = createUseStyles({
    section: {
        margin: {
            top: "2em",
            bottom: "5em",
            left: "auto",
            right: "auto"
        
        }
    },
    header: {
        fontFamily: 'Helvetica-Bold',
        fontSize: '52px',
        color: '#297FCA',
        letterSpacing: '-2px',
        textAlign: 'center',
        lineHeight: '58px'
    },
    subtitle: {
        fontFamily: "MaxwellSmCaps-Book",
        opacity: 0.64,
        fontSize: "30px",
        color: "#297FCA",
        letterSpacing: 0,
        textAlign: "center",
        lineHeight: "30px",
    },
    highlight: {
        fontWeight: "bold",
        opacity: 1.0,
        fontFamily: "MaxwellSmCaps-Book",
        fontSize: "26px",
        color: "#297FCA",
        letterSpacing: 0,
        lineHeight: "30px"
    },
    text: {
        opacity: 0.8,
        fontFamily: "Helvetica",
        fontSize: "22px",
        color: "#297FCA",
        letterSpacing: 0,
        lineHeight: "38px"
    },
    image: {
        paddingLeft: "3em",
        height: "100%"
    }
});