import React from 'react';
import { globalStyles } from '../../../../global.css';
import {styles} from './ApppishForm.css';

export const ApppishContactForm = (props) => {
    const classStyles = styles();
    const globalCss = globalStyles();


    return (
        <div className={`container`}>
            <div className={`row`}>
            <div className={`col-lg-6 col-md-12`}>
            <div className={`input-group row`}>
            <span class={`input-group-text ${classStyles.label} ${globalCss.transparent}`}  id="addon-wrapping">Name</span>
                <input name={`name`} onChange={(event)=>props.updateField(event)} id="nameInput" type="text" className={`${classStyles.input} form-control`} required/>

            </div>

            <div className={`input-group row`}>
            <span class={`input-group-text ${classStyles.label} ${globalCss.transparent}`}  id="addon-wrapping">Project Name</span>
                <input name={`projectName`} onChange={(event)=>props.updateField(event)} id="projectName" type="text" className={`${classStyles.input} form-control`} required/>

            </div>


            <div className={`input-group row`}>
            <span class={`input-group-text ${classStyles.label} ${globalCss.transparent}`}  id="addon-wrapping">Email</span>
                <input name={`email`} onChange={(event)=>props.updateField(event)} id="emailInput" type="email" className={`${classStyles.input} form-control`} required/>
               

            </div>

            <div className={`input-group row`}>
               
                <span class={`input-group-text ${classStyles.label} ${globalCss.transparent}`} id="addon-wrapping">Company</span>
                <input name={`company`} onChange={(event)=>props.updateField(event)} id="companyInput" type="text" className={`${classStyles.input} form-control`} />
               

            </div>

            <div className={`row`}>
            <span class={`input-group-text ${classStyles.label} ${globalCss.transparent}`}  id="addon-wrapping">Contact Method</span>
            </div>
            <div className={`form-check form-check-inline`}>


                <div class="form-check">
                <input onChange={(event)=>props.updateField(event)}  className="form-check-input" type="radio" name="contactMethod" id="Phone"/>
  <label className={`form-check-label ${globalCss.subtitle} ${classStyles.smallText}`} htmlFor="phone">
   Phone
  </label>
                </div>

                <div class="form-check">
                <input onChange={(event)=>props.updateField(event)}  className="form-check-input" type="radio" name="contactMethod" id="Zoom"/>
  <label className={`form-check-label ${globalCss.subtitle} ${classStyles.smallText}`} htmlFor="video">
   Zoom
  </label>
                </div>

                <div class="form-check">
                <input onChange={(event)=>props.updateField(event)} className="form-check-input" type="radio" name="contactMethod" id="In-Person"/>
                <label className={`form-check-label ${globalCss.subtitle} ${classStyles.smallText}`} htmlFor="person">
                In-Person
                </label>
                </div>
 
            
            </div>
            <div className={`input-group row`}>
                <input name={`contactId`} placeholder={props.placeholder()} onChange={(event)=>props.updateField(event)} id="contactId" type="text" className={`${classStyles.input} form-control`} required/>
               

            </div>
            </div>

            <div className={`col-lg-6 col-md-12`}>
                <div className={`input-group row`}>
                <span class={`input-group-text ${classStyles.label} ${globalCss.transparent}`}  id="addon-wrapping">Project Details</span>

                <input name={`projectDetails`} onChange={(event)=>props.updateField(event)} className={`${classStyles.input} ${classStyles.projectDetails} mb-3`}  type="textarea" />
                </div>
                <div className={`row`}>
                    <div onClick={()=>props.onSubmit()} className={`btn btn-block btn-primary btn-lg my-3`}>Submit</div>
                </div>
            </div>
            </div>
        </div>
    );
}