import React from 'react';
import { ApppishBetterBusiness } from '../../components/shared/bb/ApppishBetterBusiness';
import { ApppishBio } from '../../components/shared/bio/ApppishBio';
import { ApppishForm } from '../../components/shared/contact/form/ApppishForm';
import { ApppishFeature } from '../../components/shared/featured/ApppishFeature';
import {ApppishHeader} from '../../components/shared/header/ApppishHeader';
import { ApppishStartup } from '../../components/shared/starup/ApppishStartup';
import {ApppishFooter} from '../../components/shared/footer/ApppishFooter';
import {MockHeaderLeftTextObj, MockHeaderRightImgUrl} from '../../mocks/MockObjs';
import { ApppishTechStack } from '../../components/shared/techstack/ApppishTechStack';


export const HomeScreen = ({props}) => {
    return (
        <main>
            <ApppishHeader
            headerLeft={MockHeaderLeftTextObj} 
            headerRight={MockHeaderRightImgUrl}>
            </ApppishHeader>
            <ApppishFeature></ApppishFeature>
            <ApppishBetterBusiness></ApppishBetterBusiness>
            <ApppishStartup></ApppishStartup>
            <ApppishBio></ApppishBio>
            <ApppishFooter>
                <ApppishTechStack></ApppishTechStack>
            </ApppishFooter>
            
        </main>
    );
}