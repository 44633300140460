import React from 'react';
import { motion } from "framer-motion";
import { useStyles } from './Header.css';


export const ApppishHeader = (props) => {
    const classStyles = useStyles();
    const { headerLeft, headerRight } = props;
    return (
        <div className={classStyles.main}>
            <div className="row">
            <div className="col-md-6">
                <div className="row justify-content-center">
                    <img src={headerLeft.top} className={`${classStyles.leftContainerImg} img-fluid`} />
                </div>
                <div className="row justify-content-center" >
                    <h2 className={classStyles.leftContainerHeaderText}>
                        {headerLeft.mid}
                    </h2>
                </div>
                <div className="row justify-content-center">
                    <h3 className={classStyles.headerLeftText}>
                        {headerLeft.bottom}
                    </h3>
                </div>
            </div>

            <div className="col-md-6 col-sm-12">
                <motion.div transition={{duration: 1.3, repeatType: 'reverse', repeat: Infinity}} animate={{ y: 20 }} className="row align-content-center">
                    <img src={headerRight.mid} className={`${classStyles.headerRightImg} img-fluid`} />
                </motion.div>
            </div>
            </div>
        </div>


    );
}



