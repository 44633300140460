import {createUseStyles} from 'react-jss';

export const styles = createUseStyles({
    mainImgContainer: {
        maxWidth: "18em"
    },
    mainImg: {
        borderRadius: "20em",
        maxWidth: '180px',
        maxHeight: '180px',
        overflow: "clip"
    },
    bioCircle: {
        backgroundColor: "#3FA2F7",
        maxWidth: "7em",
        maxHeight: "7em",
        width: '100%',
        height: '50em',
        borderRadius: "50%",
        padding: "20px",
        margin: "0 auto"
    
    },
        bioCircleOffSet: {
            backgroundColor: "#E4F1FD"
        },
        bioText: {
            maxWidth: "70%",
            marginTop: "2em",
            marginBottom: "2em",
            opacity: 0.8,
            fontFamily: "Helvetica-Bold",
            fontSize: "20px",
            color: "#297FCA",
            letterSpacing: 0,
            textAlign: "center",
            lineHeight: "30px",
        },
        bioSubTitle: {
            opacity: 0.8,
            fontSize: "0.8em",
            color: "#297FCA",
            letterSpacing: 0,
            textAlign: "center",
            marginBottom: 0
            
        }
    
});