import { motion } from 'framer-motion';
import React, {useState} from'react';
import {globalStyles} from '../../../../global.css';
import {styles} from './ApppishForm.css';


export const ApppishTechTopic  = (props)  => {
    const classStyles = styles();
    const globalCss = globalStyles();
    const [selected, setSelected] = useState(false);

    const updateSelected = () => {
        setSelected(!selected);
        props.topicSelected({name: props.name, selected: !selected})
    }

    return (
    <motion.div
    transition={{duration: 1.3, repeatType: 'reverse', repeat: Infinity}} animate={{ y: 10 }}
    onClick={()=>updateSelected()} className={`col-md-4 col-lg-3 col-sm-6 ${classStyles.topic}`}>
                    <h3 className={`${globalCss.subtitle} ${selected ? classStyles.smallText : ''} `} >{props.name}</h3>
                </motion.div>);
}

