import React from 'react';
import { styles } from './ApppishBio.css';
import BIOPIC from '../../../public/bioPic.jpg';

export const ApppishBioPic = (prop) => {
    const classStyles = styles();
    return (
        <div className={`container`}>
            <div className={`row justify-content-center`}>

            <div className={`col align-items-center d-md-none d-lg-block ${classStyles.bioCircle}  ${classStyles.bioCircleOffSet} align-self-center`}/>
            <div className={`col align-items-center d-md-none d-lg-block  ${classStyles.bioCircle} align-self-center`}/>
                    
                <div className={`col justify-content-center d-flex ${classStyles.mainImgContainer}`}>
                    <img className={`align-self-center ${classStyles.mainImg}`} src={BIOPIC} />
                </div>
                <div className={`col align-items-center d-md-none d-lg-block  ${classStyles.bioCircle} align-self-center`}/>
                <div className={`col align-items-center d-md-none d-lg-block ${classStyles.bioCircle}  ${classStyles.bioCircleOffSet} align-self-center`}/>
                    
                    
            </div>

        </div>
    );
}